.leaflet-tile-pane    { z-index: 0 !important; }
.leaflet-overlay-pane { z-index: 0 !important; }
.leaflet-shadow-pane  { z-index: 0 !important; }
.leaflet-marker-pane  { z-index: 0 !important; }
.leaflet-tooltip-pane   { z-index: 0 !important; }
.leaflet-popup-pane   { z-index: 0 !important; }
.leaflet-pane { z-index: 0 !important;}
.leaflet-control { z-index: 0 !important;}
.leaflet-top, .leaflet-bottom { z-index: 0 !important; }
.leaflet-map-pane canvas { z-index: 0 !important; }
.leaflet-map-pane svg    { z-index: 0 !important; }